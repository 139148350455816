<template>
  <div>
    <div class="table-container-mundial is-centered">
      <div class="table-container-mundial__table-mundial" ref="tableContainer">
        <div class="table-container-mundial__table-mundial__header-container" :style="headerStyles">
          <img :src="headerIcon" width="26" alt="Logo" />
          <span :style="headerStyles">Posiciones</span>
        </div>
        <div class="table-container-mundial__table-mundial__control-container">
          <div
            class="btn-control-table-mundial "
            :class="menuActiveOption == 'fase' ? 'active' : ''"
            @click="clickMenuTable('fase')"
          >
            Fase de Grupos
          </div>
          <div
            class="btn-control-table-mundial"
            :class="menuActiveOption == 'playoff' ? 'active' : ''"
            @click="clickMenuTable('playoff')"
          >
            Play-Off
          </div>
        </div>
        <template v-if="menuActiveOption == 'fase'">
          <div class="table-container-mundial__table-mundial__cont-groups-movil">
            <TableMundialSliderInformador
              :data="tableDataMundial"
              :select="indexRowSelectTeam"
              :season="season"
              :key="indexRowSelectTeam"
              :teamselect="teamSelect"
            />
          </div>
          <div class="table-container-mundial__table-mundial__footer-references">
            <div><span class="abbr-references">JJ</span> - Juegos jugados</div>
            <div><span class="abbr-references">JG</span> - Juegos ganados</div>
            <div><span class="abbr-references">JE</span> - Juegos empatados</div>
            <div><span class="abbr-references">JP</span> - Juegos perdidos</div>
            <div><span class="abbr-references">GF</span> - Goles a favor</div>
            <div><span class="abbr-references">GC</span> - Goles en contra</div>
            <div><span class="abbr-references">DG</span> - Diferencia de goles</div>
            <div><span class="abbr-references">PTS</span> - Puntos</div>
          </div>
        </template>
        <template v-else>
          <TableMundialPlayOffInformador :season="season" />
        </template>
      </div>
    </div>
    <div
      :class="{ 'min-div-footer-inner': !fullTableShowHidden, 'min-div-footer': fullTableShowHidden }"
      style="width: 100%"
    >
      <div class="table-container-mundial__table-mundial__footer">
        <div>
          <img class="img-table-footer" src="/images/logo_golstats_horizontal_black.svg" width="87" alt="GolStats" />
        </div>
        <div class="spacer" />
        <BetLogo
          height="28px"
          width="auto"
          url-logo="https://az755631.vo.msecnd.net/golstats-bets/partner-akron.jpg"
          url-link=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'TableMundialWidgetInformador',
  components: {
    BetLogo: () => import('@/components/Elements/BetLogo'),
    TableMundialSliderInformador: () => import('@/components/Sections/Table/TableMundialSliderInformador'),
    TableMundialPlayOffInformador: () => import('@/components/Sections/Table/TableMundialPlayOffInformador'),
  },
  props: {
    custom: {
      type: Object,
      default: null,
    },
    season: {
      type: Number,
    },
    awaitForSeason: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    season: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          this.tableData = null;
          this.getTableList(newValue);
        }
      },
    },
  },
  data() {
    return {
      tableFull: true,
      footer: 1248,
      menuActiveOption: 'fase',
      tableData: null,
      tableDataPrintAux: [],
      tableDataMundial: null,
      indexSlider: 0,
      indexTableSelectTeam: 0,
      indexRowSelectTeam: 0,
      flaghideShow: false,
      teamSelect: 392,
      url: 'https://jfxv8fqkn3.execute-api.us-west-2.amazonaws.com/prod/generalTable/',
    };
  },
  async created() {
    try {
      window.addEventListener('resize', this.myEventHandler);
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    this.footer = this.$refs.tableContainer.clientWidth;
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler);
  },
  computed: {
    headerIcon() {
      return 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png';
    },
    headerStyles() {
      return {
        color: '#cbee6e',
        background: '#132839',
        borderColor: '#cbee6e',
      };
    },
    fullTableShowHidden() {
      return this.tableFull;
    },
    widthFooter() {
      return {
        width: this.footer + 'px',
      };
    },
  },
  methods: {
    clickMenuTable(val) {
      this.menuActiveOption = val;
    },
    async getTableList(season) {
      try {
        const seasonApi = season == null ? 881 : season;
        const data = await axios
          .get(this.url + seasonApi)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getTableList(seasonApi);
            }
            return e;
          });
        this.tableData = data.data.groups;
        var contGroupsOdd = -1;
        for (let index = 0; index < this.tableData.length; index++) {
          if (index % 2 == 0) {
            contGroupsOdd++;
          }
          if (!this.tableDataPrintAux[contGroupsOdd]) {
            this.tableDataPrintAux[contGroupsOdd] = [];
          }
          Object.keys(this.tableData[index].data).forEach(a => {
            if (this.tableData[index].data[a].team_id == this.teamSelect) {
              this.indexRowSelectTeam = contGroupsOdd;
              this.indexSlider = index;
              if (index % 2 == 0) {
                this.indexTableSelectTeam = 1;
              } else {
                this.indexTableSelectTeam = 0;
              }
            }
          });
          for (let indexRever = 0; indexRever < this.tableData[index].data.length; indexRever++) {
            this.tableData[index].data[indexRever].last_five_games = this.tableData[index].data[
              indexRever
            ].last_five_games.reverse();
          }
          this.tableDataPrintAux[contGroupsOdd].push(this.tableData[index]);
        }

        this.tableDataMundial = this.tableDataPrintAux;
        this.$emit('data-fetched');
      } catch (e) {
        return Promise.reject(e);
      }
    },
    myEventHandler() {
      this.footer = this.$refs.tableContainer.clientWidth;
    },
    buttonMore() {
      this.flaghideShow = false;
    },
    buttonLess() {
      this.flaghideShow = true;
    },
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.hidden-row-group {
  display: none !important;
}
.active {
  background-color: #cbee6e;
  border: none !important;
}
.btn-control-table-mundial {
  width: 160px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #000;
  font-family: 'Avenir-Demi';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  margin-left: 12px;
  margin-right: 12px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.15px;
  text-align: center;
  color: #132839;
}
@media screen and (max-width: 680px) {
  .button-movil-show {
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  .hidden-row-group-movil {
    display: none !important;
  }
}

.button-more {
  width: 155px;
  height: 38px;

  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: -0.28px;
  text-align: center;
  color: #000;
  border-radius: 8px;
  border: solid 2px #e3eaf0;
}
.abbr-references {
  font-weight: bold;
  font-family: 'Avenir-Pro-Bold';
}
.img-table-footer {
  margin-top: 9px;
}
.min-div-footer {
  height: 41px;
  display: flex;
  display: inline-block;
  background-color: #fbfbfb;
  position: sticky;
  bottom: 0px;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
}
.min-div-footer-inner {
  height: 41px;
  display: flex;
  display: inline-block;
  background-color: #fbfbfb;
  position: sticky;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
}
.table-container-mundial {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  // background: rgb(196, 255, 247);
  &__table-mundial {
    width: 100%;
    height: 559px;
    table-layout: fixed;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    &__cont-groups {
      width: 100%;
      min-height: 200px;
      @media screen and (max-width: 450px) {
        display: none;
      }
      // background: red;
      margin-top: 20px;
      display: block;
      &__row-groups {
        display: inline-flex;
        justify-content: space-between;
        width: 96%;
        min-height: 188px;
        margin-top: 6px;
        // background: purple;
        @media screen and (max-width: 768px) {
          display: inline-block;
          margin-top: 0px;
          width: 630px;
        }
        @media screen and (max-width: 650px) {
          display: inline-block;
          margin-top: 0px;
          width: 530px;
        }
        @media screen and (max-width: 550px) {
          display: inline-block;
          margin-top: 0px;
          width: 430px;
        }
        &__item-cont-group {
          width: 48.5%;
          min-height: 188px;
          // background: green;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    &__cont-groups-movil {
      width: 100%;
      min-height: 200px;
      // background: rgb(210, 224, 253);
    }

    &__control-container {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      height: auto;
      margin-top: 6px;
    }
    &__button-container {
      width: 100%;
      height: auto;
      // background: palevioletred;
      @media screen and (max-width: 680px) {
        display: inline-block;
      }
      @media screen and (max-width: 450px) {
        display: none;
      }
    }
    &__footer-references {
      width: 290px;
      height: auto;
      display: grid;
      grid-template-columns: 54% 46%;
      grid-template-rows: min-content auto;
      font-family: 'Avenir-Pro-Medium';
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      margin: 0 auto;
      letter-spacing: -0.45px;
      color: #545454;
      padding-top: 3px;
      padding-bottom: 0px;
      // @media screen and (max-width: 768px) {
      //   width: 640px;
      // }
      // @media screen and (max-width: 650px) {
      //   width: 530px;
      // }
      // @media screen and (max-width: 550px) {
      //   grid-template-columns: 33% 33% 33%;
      //   width: 430px;
      // }

      // // @media screen and (max-width: 510px) {
      // //   grid-template-columns: 33% 33% 33%;
      // // }
      // @media screen and (max-width: 450px) {
      //   // grid-template-columns: 50% 50%;
      //   width: 395px;
      // }
      // @media screen and (max-width: 400px) {
      //   grid-template-columns: 56% 44%;
      //   width: 300px;
      // }
      // @media screen and (max-width: 380px) {
      //   width: 340px;
      // }
      // @media screen and (max-width: 360px) {
      //   width: 300px;
      // }
      // @media screen and (max-width: 340px) {
      //   width: 280px;
      // }

      & > div {
        padding-left: 10px;
        text-align: left;
        @media screen and (max-width: 450px) {
          padding-left: 5px;
        }
      }
    }
    &__header-container {
      border-bottom: 2px solid #88da69;
      width: 100%;
      background: #242424;
      height: 40px;
      font-family: 'Circular-Std-Medium';
      font-size: 24px;
      display: grid;
      grid-template-columns: 38px 1fr;
      grid-template-rows: min-content auto;

      img {
        margin-left: 11px;
        margin-top: 4px;
      }
      span {
        color: white;
        height: 34px;
        line-height: 42px;
        font-family: 'Circular-Std-Medium';
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.52px;
        text-align: center;
        color: #ffffff;
        padding-right: 38px;
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      border-radius: 2px;
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
      background-color: #fbfbfb;
      & .spacer {
        width: 1px;
        height: 0.75rem;
        background-color: #979797;
        margin: 0px 16px;
      }
    }
  }
}
</style>
